#info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#landing-container {
  height: 100%;
  padding: 0 0 0 5vw;
  background: linear-gradient(0deg, rgb(255 255 255 / 100%) 0%, rgb(125 213 255 / 100%) 100%);
}

.landing-float > * {
  height: 100%;
}

#info-box > * {
  margin: 20px;
}

#get-started-btn {
  padding: 20px;
}

#info-box > h1 {
  line-height: 1em;
  font-family: Kalam, sans-serif;
  padding-bottom: 0;
}

#info-box > h2 {
  margin-top: 0;
  padding-bottom: 5vh;
}

@font-face {
  font-family: Kalam;
  src: url("Kalam-Bold.ttf") format("truetype");
}

#floating-ticker {
  position: absolute;
  top: 10%;
  bottom: 30%;
  left: 21%;
  right: 20%;
}

#floating-orderbook {
  position: absolute;
  top: 25%;
  bottom: 20%;
  left: 40%;
  right: 12%;
  box-shadow: -0.5em 10px 30px rgb(125 213 255 / 70%);
}

#floating-leaderboard {
  position: absolute;
  top: 40%;
  bottom: 10%;
  left: 12%;
  right: 25%;
  box-shadow: -0.5em 10px 30px rgb(125 213 255 / 70%);
}

#landing-img {
  height: 7em;
  width: 7em;
  margin: 0 0 0 20px;
}

#component-floaters {
  position: relative;
}

@media only screen and (max-width: 450px) {
  #catch-phrase {
    font-size: 4em;
    text-align: center;
  }
}

@media only screen and (min-width: 450px) {
  #catch-phrase {
    font-size: 6em;
  }
}
