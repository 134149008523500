.t-iface-lpanel {
  /* Intentional double selector for specificity */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.t-iface-rpanel.t-iface-rpanel {
  /* Intentional double selector for specificity */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.t-iface-rupperbox {
  flex: 0 0 33%;
}

.t-iface-rlowerbox {
  flex: 1 1 66%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.t-iface-rlowerbox > * {
  flex: 1 1 50%;
  overflow: hidden;
}

.t-iface-trade-history-ctr > * {
  height: 100%;
}

/** Mobile **/

/* Definition of Mobile is consistent with Mui 'sm' breakpoint,
otherwise we get a weird region where there is only orderbook and MTT */
@media only screen and (max-width: 600px) {
  .t-iface-desktop {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .t-iface-mobile {
    display: none;
  }
}

.t-iface-orderbook {
  flex: 1 1 60%;
}

.t-iface-market-ticker {
  flex: 1 1 40%;
  overflow: hidden;
}
