.cancel-all-btn {
  text-align: center;
}

.order-list .uo-filler {
  flex: 1 0 0;
}

.order-list > * {
  flex: 0 0 auto;
}

.uo-table {
  flex: 0 1 auto;
}

.order-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Make orderList somewhat smaller so that we can fit more
 in in limited competitors space, but only on desktop! */
@media only screen and (min-width: 600px) {
  .order-list td,
  .order-list th {
    padding: 5px;
  }
}

/* On mobile, trim horizontal padding - we can't afford it */
@media only screen and (max-width: 600px) {
  .order-list td,
  .order-list th {
    padding: 16px 0;
  }
}
