/** Mobile **/
@media only screen and (max-width: 450px) {
  .order-component {
    width: 100%;
    text-align: center;
    padding: 3% 0%;
  }

  .order-input {
    text-align: center;
    width: 100%;
    margin: 3% 0%;
  }
}

/** Desktop **/
@media only screen and (min-width: 450px) {
  .order-component {
    width: 100%;
    text-align: center;
  }

  .order-input {
    text-align: center;
    width: 33%;
  }
}
