#root {
  height: 100vh;
}

.full-height {
  height: 100%;
  overflow: clip;
}

.full-height.scroll {
  overflow-y: auto;
}

.table-title {
  text-align: center;
}

.browser-height {
  flex: 1 1 100%;
  overflow: clip;
}

.paper-col-flex {
  display: flex;
  flex-direction: column;
}

/** Desktop **/
@media only screen and (min-width: 450px) {
  #catch-phrase {
    font-size: 6em;
  }
}
