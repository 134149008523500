#projector-right-pane {
  display: flex;
  flex-direction: column;
}

.projector-scale th {
  padding: 3px;
  font-size: 1.5em;
}

.projector-scale td {
  padding: 3px;
  font-size: 2em;
}
