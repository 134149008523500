@keyframes market-ticker-flash {
  0% {
    background-color: rgb(25 118 210);
    color: white;
    opacity: 1;
  }

  100% {
    background-color: inherit;
    color: black;
  }
}

.market-ticker-row {
  animation: market-ticker-flash 1000ms 1 ease;
}

/* Intentional duplicate to increase specificity */
.market-ticker-row.market-ticker-row > * {
  color: inherit;
}

.leaderboard table {
  table-layout: fixed;
}
