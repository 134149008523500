.navlink {
  text-decoration: none;
  padding: 0 10px;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
}

.navlink-drop {
  color: #1976d2;
  text-decoration: none;
}

.navlink-active {
  position: relative;
}

.navlink-array {
  height: 100%;
}

.setttings-dropdown {
  align-self: center;
  display: flex;
  justify-content: right;
}

.navlink-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 3px solid white;
}

.logo {
  margin-right: 8px;
  height: 2em;
  align-self: center;
}

.header-root {
  align-items: baseline;
  position: relative;
}

.header-root > * {
  flex-basis: 100%;
}

#appbar-no-shadow {
  /* This is an ID instead of a class in lieu of !important. */
  box-shadow: none;
}

.header-side-elements {
  position: absolute;
}

.middle-title {
  align-items: baseline;
  justify-content: center;
}

.mobile-middle-title {
  justify-content: center;
}

#middle-title-text {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 0) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: flex;
  }

  .mobile-only-block {
    display: block;
  }
}

@media (min-width: 900px) {
  .desktop-only {
    display: flex;
  }

  .mobile-only,
  .mobile-only-block {
    display: none;
  }
}
