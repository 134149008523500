.orderbook-center-quotes-outer {
  flex: 1 0 0;
  overflow: hidden;
}

.orderbook-center-quotes {
  height: 100%;
  position: relative;
}

.orderbook-center-quotes > * {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
